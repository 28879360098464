<script>
import {mapGetters} from "vuex";

export default {
  name: 'CommunityCards',
  components: {
    GameCommunityCard: () => import(/* webpackChunkName: "game-community-card-v2" */ '@/components/Game/CommunityCard.vue'),
  },
  computed: {
    ...mapGetters('game', ['roomInfo', 'isShowdown', 'isShowResult', 'canPlayGame', 'bestHandDetails']),
    cardBack() {
      return require(`@/assets/v2/img/card_back_1.webp`);
    },
    communityCards() {
      return this.roomInfo.boards || [];
    },
    isCardOpen() {
      return [this.isShowdown || this.isShowResult, this.canPlayGame].every(is => is);
    },
    bestHand() {
      if (!this.isShowResult) {
        return [];
      }

      return this.bestHandDetails ? this.bestHandDetails.cards || [] : [];
    },
  },
  methods: {
    isCardInBestHand(card) {
      return this.bestHand.indexOf(card.nick) !== -1;
    },
    getCardStatus(card) {
      if (!this.isShowResult) {
        return '';
      }

      return this.isCardInBestHand(card) ? 'active' : 'disabled';
    },
  }
}
</script>

<template>
  <transition-group
    appear
    name="slide"
    mode="out-in"
    tag="div"
    class="area_game_card"
  >
    <GameCommunityCard
      v-for="(card, n) in communityCards"
      :key="`community-card_${n}`"
      :card="card"
      :card-status="getCardStatus(card)"
      :data-index="n"
    >
      <template v-slot:default="{showFront, asset}">
        <img v-show="!showFront" :src="cardBack" class="card-asset">
        <img v-show="showFront" :src="asset" class="card-asset"/>
      </template>
    </GameCommunityCard>
  </transition-group>
</template>

<style>
.slide-enter-active {
  animation: slide-in .2s ease-in-out forwards;
  animation-fill-mode: both;
}

@keyframes slide-in {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.game_card {
  display: inline-block;
}
</style>