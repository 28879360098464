<script>
import {mapGetters} from "vuex";
import pokerCommon from "@/pokerCommon";
import * as gameSock from "@/gameSock";
import {GameType} from "../../gameCommon";

export default {
  components: {
    GamePlayerTurn: () => import(/* webpackChunkName: "game-player-turn-v2" */ '@/components/Game/PlayerTurn.vue'),
    GamePlayerHands: () => import(/* webpackChunkName: "game-player-hands-v2" */ '@/components/Game/PlayerHands.vue'),
    GamePlayerCash: () => import(/* webpackChunkName: "game-player-cash-v2" */ '@/components/Game/PlayerCash.vue'),
    GamePlayerBetAmount: () => import(/* webpackChunkName: "game-player-bet-amount-v2" */ '@/components/Game/PlayerBetAmount.vue'),
    GamePlayerBetPayback: () => import(/* webpackChunkName: "game-player-bet-payback-v2" */ '@/components/Game/PlayerBetPayback.vue'),
    GamePlayerRoleBadge: () => import(/* webpackChunkName: "game-player-role-badge-v2" */ '@/components/Game/PlayerRoleBadge.vue'),
    GamePlayerActionBadge: () => import(/* webpackChunkName: "game-player-action-badge-v2" */ '@/components/Game/PlayerActionBadge.vue'),
    GamePlayerEmotion: () => import(/* webpackChunkName: "game-player-emotion-v2" */ '@/components/Game/PlayerEmotion.vue'),
    GameHandRanking: () => import(/* webpackChunkName: "game-hand-ranking-v2" */ '@/components/Game/HandRanking.vue'),
  },
  props: {
    player: {
      type: Object,
      required: true,
    },
    me: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      isNewRound: false,
      boardsLength: 0,
      isHandNameWait: false,
      timer: null,
    }
  },
  computed: {
    ...mapGetters('game', ['roundKey', 'myInfo', 'roomInfo', 'isPlaying', 'isPaused', 'isGameOver', 'isShowResult', 'isShowdown', 'currentRoomState', 'lastRoomState', 'isRelayGame', 'isTournamentGame']),
    ...mapGetters('user', ['isGameExitReserved']),
    avatarImage() {
      if (!(this.player && this.player.photo)) {
        const tempAvatars = [2, 6, 7, 14, 15];
        const tmpAvatar = tempAvatars[Math.floor(Math.random() * tempAvatars.length)];
        return require(`@/assets/v2/img/avatar/avatar${tmpAvatar < 10 ? '0' : ''}${tmpAvatar}.jpg`);
      }

      let avartarNumber = (this.player.photo || '').toString();
      avartarNumber = avartarNumber.match(/\d/gmi).join('');
      avartarNumber = parseInt(avartarNumber);

      return require(`@/assets/v2/img/avatar/avatar${avartarNumber < 10 ? '0' : ''}${avartarNumber}.jpg`);
    },
    turn() {
      return this.player.isTurn || false;
    },
    role() {
      if (this.player.isDealer) {
        return 'd';
      } else if (this.player.isSmallBlind) {
        return 'sb'
      } else if (this.player.isBigBlind) {
        return 'bb'
      } else if (this.player.isPostBlind) {
        return 'pb'
      }

      return '';
    },
    /* eslint-disable no-unused-vars */
    isVisibleDetails() {
      if (this.isSitout) {
        return false;
      }

      const {playState} = this.player;
      return [this.playState.PLAYING, this.playState.FOLD].some(state => state === playState);
      // return [this.playState.PLAYING, this.playState.FOLD].some(state => state === playState) && reserveState === this.playState.NONE;
    },
    isVisiblePaybackAction() {
      if (this.player.payback < 1) {
        return false;
      }

      return this.player.betCash > 0 || this.roomInfo.state === this.roomState.RiverAction;
    },
    isSitout() {
      const isSitout = this.player && (this.player.isSitout || false);

      if (isSitout) {
        return this.player.reserveState !== this.playState.WAIT
      }

      return this.player.reserveState === this.playState.SITOUT
    },
    isPlayerDisconnected() {
      return this.player && (this.player.isDisconnected || false);
    },
    isAbnormal() {
      return this.isSitout || this.isPlayerDisconnected;
    },
    isPlayerPlaying() {
      const playState = this.player.playState || -1;
      return playState === this.playState.PLAYING || playState === this.playState.FOLD;
    },
    isPlayerWaiting() {
      const playState = this.player.playState || -1;
      const reserveState = this.player.reserveState || -1;

      if (playState === this.playState.FOLD && reserveState === this.playState.WAIT) {
        return true;
      }

      return reserveState >= this.playState.WAIT_FROM_ENTER && reserveState <= this.playState.WAIT_FROM_DEALDER;
    },
    isPlayerWaitingOtherPlayer() {
      const playState = this.player.playState || -1;
      return playState === this.playState.WAIT_FROM_OTHER_PLAYER;
    },
    isPlayerWaitingFromSmall() {
      return this.player.reserveState === this.playState.WAIT_FROM_SMALL;
    },
    isPlayerWaitingFromBig() {
      return this.player.reserveState === this.playState.WAIT_FROM_BIG;
    },
    playerDetailStatus() {
      if (this.isGameOver) {
        return '';
      }

      let stateMessage = '';

      if (this.isPlayerDisconnected) {
        stateMessage = '(끊김)'
      }

      if (this.isPlayerWaitingFromSmall) {
        stateMessage = '(SB)'
      }

      if (this.isPlayerWaitingFromBig) {
        stateMessage = '(BB)'
      }

      return stateMessage;
    },
    playerStatus() {
      if (this.isPlayerWaiting || this.isPlayerWaitingOtherPlayer) {
        return `참여대기`;
      }

      if (this.isSitout) {
        const reserveState = this.player.reserveState || -1;

        return (reserveState === this.playState.WAIT || reserveState === this.playState.WAIT_FROM_ENTER) ? `참여대기` : `자리비움`;
      }

      return this.player && this.player.playState === this.playState.WAIT && this.currentRoomState === this.roomState.WaitPlayer ? '참여대기' : '';
    },
    abnormalStyle() {
      if (!this.me || !this.isAbnormal) {
        return null;
      }

      return {
        width: '100%',
        height: '100%',
        backgroundSize: this.isPlayerDisconnected ? '7rem' : '5rem',
      }
    },
    // 베팅 라운드 별 나의 핸드랭킹
    myHandRanking() {
      const {handName} = this.myInfo.cardName;
      return handName || '';
    },
    handRanking() {
      if (this.isGameOver || this.player.idx === -1) {
        return '';
      }

      if (this.me) {
        return this.myHandRanking;
      }

      const {cardSetName} = this.player;
      const handName = cardSetName.handName || '';
      return handName.toLowerCase().replace(/\s/g, '');
    },
    winners() {
      const winnerInfo = this.roomInfo.winnerInfo || [];

      if (!winnerInfo.length) {
        return [];
      }

      return winnerInfo[0].winners || [];
    },
    isWinner() {
      return this.winners.some(winnerIndex => this.player.idx === winnerIndex);
    },
    isBestHand() {
      if (!this.player) {
        return false;
      }

      if (!this.isWinner) {
        return false;
      }

      return this.player.isBestHand;
    },
    lastWinningCash() {
      return this.player.lastWinningCash || 0;
    },
    isValidWinnerPot() {
      if (!this.isWinner) {
        return false;
      }

      const totalBet = this.player.totalBet || 0;

      if (totalBet <= 0) {
        return false;
      }

      return this.lastWinningCash > 0;
    },
    showWinner() {
      if (!this.winners.length || !this.isShowResult) {
        return false;
      }

      return this.isWinner;
    },
    boards() {
      return this.roomInfo.boards || [];
    },
    canRejoinGame() {
      if (this.isGameOver) {
        return false;
      }

      return this.isSitout;
    },
    isHandNameVisible() {
      if (this.isGameOver) {
        return false;
      }

      if (this.boardsLength < this.boards.length) {
        return false;
      }

      if (!this.isVisibleDetails) {
        return false;
      }

      if (!this.me) {
        return this.handRanking && !this.isHandNameWait;
      }

      return this.myHandRanking && this.myInfo.playState !== this.playState.WAIT && !this.isHandNameWait;
    },

  },
  watch: {
    isShowResult: {
      immediate: true,
      handler: 'emitWin'
    },
    isWinner() {
      this.emitWin();
    },
    lastRoomState(state, _state) {
      this.setIsNewRound(_state === this.roomState.Deal);
    },
    currentRoomState(state, _state) {
      this.setIsNewRound(state === this.roomState.Deal && _state === this.roomState.ShowResult);
    },
    boards: {
      deep: true,
      handler: 'setHandNameTimer'
    },
    showWinner: {
      immediate: true,
      handler: 'playWinSound',
    },
    turn() {
      !this.turn && pokerCommon.stopSound("countdown");
    },
    boardsLength() {
      this.playCommunityCardSound();
    },
    isPlayerDisconnected() {
      this.onPlayerSocketIssue();
    }
  },
  methods: {
    onPlayerSocketIssue() {
      this.$store.commit('game/ADD_PLAYER_SOCKET_ISSUE', this.player.sn);
    },
    emitWin() {
      if (!this.isWinner || !this.isBestHand) {
        return;
      }

      this.$emit('win', this.handRanking);
      this.$emit('card-highlight');
    },
    async returnToGame() {
      try {
        await gameSock.poker_change_user_state(
          this.$socket,
          this.roomInfo.sn,
          `sitin`
        );

        //나가기예약 상태 초기화
        this.$store.commit('user/SET_IS_GAME_EXIT_RESERVED', false)

      } catch (e) {
        alert(e)
      }
    },
    playCommunityCardSound() {
      if (!this.boards.length) {
        return;
      }

      if (this.boardsLength >= 3) {
        pokerCommon.playSound('cardssg');
        setTimeout(() => pokerCommon.playSound('cardhook'), 500);
      }
    },
    setIsNewRound(isNewRound) {
      this.isNewRound = isNewRound;
    },
    playWinSound() {
      if (this.handRanking && !this.isHandSound) {
        this.isHandSound = true;

        const handRanking = this.handRanking.match(/[a-z]/gi).join('').toLowerCase();
        pokerCommon.playSound(handRanking);
      }
    },
    setHandNameTimer() {
      if (!this.boards.length) {
        this.boardsLength = 0;
      }

      if (this.boardsLength >= this.boards.length) {
        return;
      }

      this.boardsLength = this.boards.length;
      this.isHandNameWait = true;

      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(() => {
        this.isHandNameWait = false;
        clearTimeout(this.timer);
      }, 1800);
    },
    showPlayerRecordModal(){
      const displayType = this.isRelayGame ? GameType.Relay : GameType.Tournament;
      this.EventBus.$emit('show-player-record-modal', {displayType: displayType, player: this.player});
    }
  },
}
</script>

<template>
  <div class="inner_game_player">
    <div class="btn_score" @click="showPlayerRecordModal"></div>
    <div class="profile" >
      <div class="centered">
        <img :src="avatarImage" alt="">
      </div>
    </div>

    <div v-if="isPlayerDisconnected || isSitout" class="profile_black sitout">
      <span v-if="playerStatus === '참여대기' || playerStatus === '자리비움'" class="user_state wait">
        <img src="@/assets/v2/svg/ico_wait.svg" alt="" :width="me ? 28 : 20" :class="`mb-${me ? 3 : 1} mt-${me ? 5 : 3}`">
        <br/>{{ playerStatus }}
        <br/>{{ playerDetailStatus }}
      </span>
      <!-- 네트워크 끊김 -->
      <span v-else-if="isPlayerDisconnected" class="user_state plugout"></span>
      <!-- 자리비움 -->
      <span v-else-if="isSitout" class="user_state sitout" :style="abnormalStyle"></span>
    </div>

    <div v-else-if="!isPlayerPlaying && playerStatus === '참여대기'" class="profile_black">
      <span class="user_state wait">
        <img src="@/assets/v2/svg/ico_wait.svg" alt="" :width="me ? 28 : 20" :class="`mb-${me ? 3 : 1} mt-${me ? 5 : 3}`">
        <br/>{{ playerStatus }}
        <br/>{{ playerDetailStatus }}
      </span>
    </div>

    <div v-else-if="isPlayerWaitingOtherPlayer" class="profile_black">
      <span class="user_state wait">
        <img src="@/assets/v2/svg/ico_wait.svg" alt="" :width="me ? 28 : 20" :class="`mb-${me ? 3 : 1} mt-${me ? 4 : 3}`">
        <br/>{{ playerStatus }}
        <br/>{{ playerDetailStatus }}
      </span>
    </div>

    <div v-else-if="player.playState === playState.FOLD" class="profile_black"></div>

    <div class="player_info">
      <div class="text-center">
        <div class="player_name">{{ player.name }}</div>
      </div>
      <div class="text-center">
        <GamePlayerCash :cash="player.cash" :is-payback="isVisiblePaybackAction"/>
      </div>

      <div class="text-center" v-if="me && canRejoinGame">
        <button class="reconnect-button" @click.prevent.stop="returnToGame">
          참여하기
        </button>
      </div>
    </div>

    <!-- 플레이어 롤 정보 -->
    <GamePlayerRoleBadge v-if="role && isVisibleDetails" :role="role"/>
    <!-- 플레이어 베팅 캐시 -->
    <GamePlayerBetAmount v-if="player.betCash > 0" :bet-amount="player.betCash"/>
    <!-- 플레이어 오버베팅 페이백 -->
    <GamePlayerBetPayback v-if="isVisiblePaybackAction" :player="player" :me="me"/>
    <!-- 카운트다운 -->
    <GamePlayerTurn v-if="turn" :player="player" :me="me" @countdown="turn = false"/>
    <!-- 플레이어 액션 정보 -->
    <GamePlayerActionBadge v-if="player" :player="player"/>
    <!-- 플레이어 핸드 -->
    <GamePlayerHands :key="roundKey" :player="player" :me="me"/>
    <!-- 이모티콘 -->
    <GamePlayerEmotion :player="player" :me="me"/>
    <!-- 본인의 탑 족보-->
    <GameHandRanking v-if="isHandNameVisible" hand-type="player" :hand-ranking="handRanking"/>

    <!-- 승리 -->
    <div v-if="showWinner" class="area_winner">
      <div class="point" v-show="isValidWinnerPot">+{{ lastWinningCash | formatCash }}</div>
      <div class="txt">WINNER!</div>
    </div>
  </div>
</template>

<style scoped>
.reconnect-button {
  display: flex;
  overflow: hidden;
  margin: 8px auto;
  padding: 8px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: all 150ms linear;
  text-align: center;
  white-space: nowrap;
  text-decoration: none !important;
  text-transform: none;
  text-transform: capitalize;
  color: #fff;
  background: #416dea;
  border: 0 none;
  border-radius: 9999px;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.3;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  justify-content: center;
  align-items: center;
  flex: 0 0 160px;
}

.reconnect-button:hover {
  font-weight: bold;
  font-size: 1.25rem;
}

.reconnect-button:active {
  background-color: #d5d6d6;
  opacity: 1;
}
</style>